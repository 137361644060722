import React from 'react';
import classNames from 'classnames';
import s from './Button.module.scss';
import Spinner from '../../Loader/Spinner/Spinner';

const BUTTON_VARIANTS = {
  primary: 'primary',
  light: 'light',
  danger: 'danger',
};

const Button = ({
  children, className, prev, next, variant = BUTTON_VARIANTS.primary, loading, disabled, ...props
}) => (
  <button
    {...props}
    disabled={loading || disabled}
    className={classNames(s.root, s[`variant_${variant}`], { [className]: className })}>
    {loading
      ? <div className={s.loader}><Spinner /></div>
      : <>
        {prev && (
        <span className={s.buttonArrow}>
          <svg className={s.headerLogo__icon}>
            <use xlinkHref="#prev" />
          </svg>
        </span>
        )
      }
        <span className={s.buttonText}>
          {children}
        </span>
        {next && (
        <span className={s.BuutonArrow}>
          <svg className={s.headerLogo__icon}>
            <use xlinkHref="#next" />
          </svg>
        </span>
        )}
      </>
    }

  </button>
);

export default Button;
