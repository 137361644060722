/* eslint-disable consistent-return */
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import NoItem from '../../../../components/NoItem/NoItem';
import { Button } from '../../../../components/UI';
import Modal from '../../../../components/UI/Modal/Modal';
import { ALERT_TYPES, VALID_ICONS_TYPES } from '../../../../constants/names';
import { setAlert } from '../../../../redux/slices/alertSlice';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import { addImageThunk, deleteImageThunk } from '../../../../redux/thunks/settingsThunk';
import s from './ImagesModal.module.scss';

const IMAGES_TABS = [
  { name: 'Icons', key: 'icon' },
  { name: 'Banner Images', key: 'banner' },
  { name: 'Menu Images', key: 'image' },
];

const ImagesModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { images, isImagesLoading } = useSelector(state => state.settings);
  const [ activeTab, setActiveTab ] = useState(IMAGES_TABS[0]);
  const [ searchInput, setSearchInput ] = useState('');

  const activeTabData = useMemo(() => (images ? images[activeTab.key] : []), [ images, activeTab ]);

  const filteredData = useMemo(() => (
    searchInput
      ? activeTabData.filter(item => item.name.toLowerCase().includes(searchInput.toLowerCase()))
      : activeTabData
  ), [ activeTabData, searchInput ]);

  const fileHandler = (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    if (!VALID_ICONS_TYPES.includes(file.type)) {
      return dispatch(setAlert({ message: 'Invalid image type' }));
    }
    return dispatch(addImageThunk({ type: activeTab.key, image: file }));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
  };

  const deleteIcon = (id) => {
    dispatch(deleteImageThunk({ id }));
  };

  const onRecycleClick = (id) => {
    dispatch(setConfirm(() => deleteIcon(id)));
  };

  return (
    <Modal onClose={closeModal} className={s.modal}>
      <div className={s.innerModal}>
        <div className={s.header}>
          <div>Images</div>
        </div>
        <div className={s.navbar}>
          {IMAGES_TABS.map(tab => (
            <div
              key={tab.key}
              onClick={() => setActiveTab(tab)}
              className={classNames(
                s.navItem, { [s.activeNav]: activeTab.key === tab.key },
              )}>
              {tab.name}
            </div>
          ))}
        </div>
        <div className={s.searchBar}>
          <input
            placeholder='Search'
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            className={s.searchInput}
          />
          {/* <Button
            onClick={() => setSearchValue(searchInput)}
            className={s.searchButton}>
            Search
          </Button> */}
        </div>
        <div className={s.content}>
          {filteredData.length
            ? filteredData.map(image => (
              <div key={image.id} className={s.imageItem}>
                <div
                  className={classNames(s.imageWrapper, {
                    [s.iconImage]: activeTab.key === IMAGES_TABS[0].key,
                  })}>
                  <img src={process.env.REACT_APP_IMAGE_URL + image.link} alt='' />
                </div>
                <div className={s.imageInfo}>
                  <div>{image.name}</div>
                  <div
                    className={s.copyIcon}
                    onClick={() => copyToClipboard(image.name)} />
                  <div onClick={() => onRecycleClick(image.id)} className={s.recycleIcon} />
                </div>
              </div>
            ))
            : <NoItem title={'Image'} />
          }
        </div>
        <div className={s.actions}>
          <div className={s.dropzone}>
            <label className={classNames(s.uploadButton, { [s.uploadButtonDisabled]: isImagesLoading })} htmlFor='icon'>
              Upload
            </label>
            <input
              onChange={fileHandler}
              disabled={isImagesLoading}
              type='file'
              id='icon'
              className={s.fileInput}
              accept=".jpg,.jpeg,.png,.jfif, .svg" />
          </div>
          <Button onClick={closeModal} variant='light'>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImagesModal;
