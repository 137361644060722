import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterCard from '../../../components/FilterCard/FilterCard';
import Heading from '../../../components/Heading/Heading';
import { REPORT_TEMPLATE_CONFIGS } from '../../../configs/report.configs';
import SideBar from '../components/SideBar/SideBar';
import s from '../CaseEventAnalysis.module.scss';
import NoItem from '../../../components/NoItem/NoItem';
import { getReportAnalysThunk } from '../../../redux/thunks/reportAnalysThunk';
import { EVENT_KEYS, QUESTION_KEYS, TEMPLATE_TYPES } from '../../../constants/names';
import CheckboxPure from '../../../components/UI/Checkbox/CheckboxPure';
import { resetReportAnalys } from '../../../redux/slices/reportAnalysSlice';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';
import { selectModeratorActiveEvent } from '../../../redux/slices/moderatorSlice';

const { CHAPTER_TYPE, CHAPTER_NAME, ICON } = QUESTION_KEYS;

const Report = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);
  const { analys, isLoading } = useSelector(state => state.reportAnalys);
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const [ activeMenu, setActiveMenu ] = useState(0);
  const [ isOnlyTop, setIsOnlyTop ] = useState(false);
  const { eventsIds } = useSelector(state => state.filteredEvents.eventsData);
  const filterCases = useSelector(state => state.filteredEvents.filters[EVENT_KEYS.CASE_LIST]);
  const moderatorEvent = useSelector(selectModeratorActiveEvent);

  const allReports = useMemo(() => {
    if (!analys?.report) return [];
    return Object.values(analys.report);
  }, [ analys ]);

  const activeTabData = allReports.length ? allReports[activeMenu] : null;
  const activeTabConfigs = activeTabData
    ? REPORT_TEMPLATE_CONFIGS[activeTabData.question[CHAPTER_TYPE]] : null;

  const isRanking = activeTabData?.question[CHAPTER_TYPE] === TEMPLATE_TYPES.RANKING;

  useEffect(() => {
    if (eventsIds.length || (moderatorEvent && isModerator)) {
      const data = {
        events: !isModerator ? eventsIds : [ moderatorEvent.id ],
      };
      if (!isModerator) {
        data.caseId = filterCases[0];
      }
      dispatch(getReportAnalysThunk(data));
    }
    return () => {
      dispatch(resetReportAnalys());
    };
  }, [ eventsIds, moderatorEvent ]);

  return (
    <div className={s.wrapper}>
      <Heading reset={resetReportAnalys}>
        {analys && analys.doctors && !isRanking && <div
          onClick={() => setIsOnlyTop(prev => !prev)}
          className={s.headingCheckboxRoot}>
          <div className={s.headingCheckbox}>
            <CheckboxPure big checked={isOnlyTop} />
          </div>
          <div className={s.headingCheckboxTitle}>
            <div>Show Top 10</div>
            <div>Answers Only</div>
          </div>
        </div>}
      </Heading>
      {!isModerator && <FilterCard />}
      <div className={s.contentBoard}>
        {!isLoading && (analys && analys.doctors
          ? <>
            <SideBar open={isSidebarOpen}>
              {allReports.map((item, idx) => (
                <SideBar.Item
                  key={item.question[CHAPTER_NAME]}
                  toggleSidebar={() => setIsSidebarOpen(prev => !prev)}
                  active={activeMenu === idx}
                  onClick={() => setActiveMenu(idx)}
                  open={isSidebarOpen}
                  text={item.question[CHAPTER_NAME]}
                  icon={item.question[ICON]} />
              ))}
            </SideBar>
            <div className={s.currentPage}>
              {activeTabConfigs
              && <activeTabConfigs.Component
                isOnlyTop={isOnlyTop}
                data={isRanking ? activeTabData.answers : activeTabData}
                title={activeTabData.question[CHAPTER_NAME]} />}
            </div>
          </>
          : <div className={s.noEvent}><NoItem analys /></div>)
        }
      </div>
    </div>
  );
};

export default Report;
