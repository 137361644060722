import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATIENT_BASE_INFO_KEYS } from '../../configs/patients.configs';

import { EVENT_KEYS, EVENT_TYPE_NAMES } from '../../constants/names';
import { toggleFilterModal } from '../../redux/slices/filteredEventsSlice';
import { Card } from '../UI';
import s from './FilterCard.module.scss';
import FilterCardLoader from './FilterCardLoader';

const {
  AREA, CASE_LIST, DATE_FROM, DATE_TILL, EVENT_TYPE,
} = EVENT_KEYS;

const {
  GENDER, AGE, FIRST_NAME, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const leftTitles = [
  { key: AREA, name: 'Therapeutic Area' },
  { key: CASE_LIST, name: 'Case' },
  { key: DATE_FROM, name: 'Date (on/from)' },
  { key: DATE_TILL, name: 'Date (till)' },
];
// const rightTitles = [
//   { key: CITY, name: 'City' },
//   { key: COUNTRY, name: 'Country' },
//   { key: REGION, name: 'Region' },
//   { key: EVENT_TYPE, name: 'Event Type' },
// ];

const FilterCard = () => {
  const dispatch = useDispatch();
  const [ isActive, setIsActive ] = useState(false);
  const {
    filters, allItems, optionsLoading, eventsData,
  } = useSelector(state => state.filteredEvents);
  const { doctors, events } = eventsData;
  const { allRegions, allCities, allCountries } = useSelector(state => state.filteredEvents.formatedLocations);

  const { isDateActive } = filters;

  const openModal = () => {
    dispatch(toggleFilterModal(true));
  };

  return (
    <div>
      {!optionsLoading
        ? <Card
            closable
            onClick={() => setIsActive(prev => !prev)}
            active={isActive}
            filter={openModal}>
          <div className={s.content}>
            <div className={s.topSide}>
              <div className={classNames(s.filterRow, s.leftRow)}>
                <div>Doctors:</div>
                <div>
                  {events.length ? doctors : '-'}
                </div>
              </div>
            </div>
            {isActive && <div className={s.middleSide}>
              <div className={s.leftSide}>
                {leftTitles.map(item => (
                  <div key={item.key} className={classNames(s.filterRow, s.leftRow)}>
                    <div>{item.name}</div>
                    <div>
                      {
                    (item.key === CASE_LIST || item.key === AREA)
                      ? <div className={s.filterList}>
                        {!filters[item.key].length
                        && <div>{item.key === CASE_LIST ? '-' : 'All'}</div>}
                        {(filters[item.key].length > 1
                         && filters[item.key].length === allItems[item.key].length)
                          ? <div>All</div>
                          : filters[item.key].map((el, i) => {
                            const isCase = item.key === CASE_LIST;
                            const currentCase = isCase
                              ? allItems.cases.find(cases => cases.id === el) : null;
                            const baseInfo = currentCase?.baseInfo || {};
                            return <div key={i}>
                              {isCase
                                ? `${baseInfo[GENDER]} ${baseInfo[FIRST_NAME]}, ${
                                  baseInfo[AGE]} - ${baseInfo[VISIT]} Visit`
                                : el}
                            </div>;
                          })}
                      </div>
                      : <div>{isDateActive ? dayjs(filters[item.key]).format('MMMM D, YYYY') : '-'}</div>

                  }
                    </div>
                  </div>
                ))}
              </div>
              <div className={s.verticalLine} />
              {isActive && <div className={s.rightSide}>
                <div className={classNames(s.filterRow, s.rightRow)}>
                  <div>City</div>
                  <div>
                    <div className={s.filterList}>
                      {!allCities.checkCount
                        ? <div>-</div>
                        : allCities.items.map((el) => {
                          if (el.unchecked) return null;
                          return <div key={el.name}>
                            {el.name}
                          </div>;
                        })}
                    </div>
                  </div>
                </div>
                <div className={classNames(s.filterRow, s.rightRow)}>
                  <div>Country</div>
                  <div>
                    <div className={s.filterList}>
                      {!allCountries.checkCount
                        ? <div>-</div>
                        : allCountries.items.map((el) => {
                          if (el.unchecked) return null;
                          return <div key={el.name}>
                            {el.name}
                          </div>;
                        })}
                    </div>
                  </div>
                </div>
                <div className={classNames(s.filterRow, s.rightRow)}>
                  <div>Region</div>
                  <div>
                    <div className={s.filterList}>
                      {!allRegions.checkCount ? <div>-</div>
                        : allRegions.items.map((el) => {
                          if (el.unchecked) return null;
                          return <div key={el.name}>
                            {el.name}
                          </div>;
                        })}
                    </div>
                  </div>
                </div>
                <div className={classNames(s.filterRow, s.rightRow)}>
                  <div>Event Types</div>
                  <div>
                    <div>
                      {filters[EVENT_TYPE].map(type => (
                        <div key={type} className={s.filterItem}>{EVENT_TYPE_NAMES[type]}</div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* {rightTitles.map(item => (
                  <div key={item.key} className={classNames(s.filterRow, s.rightRow)}>
                    <div>{item.name}</div>
                    <div>
                      {item.key === EVENT_TYPE
                        ? <div>{EVENT_TYPE_NAMES[filters[item.key]]}</div>
                        : <div className={s.filterList}>
                          {!filters[item.key].length && <div>All</div>}
                          {(filters[item.key].length > 1
                         && filters[item.key].length === allItems[item.key].length)
                            ? <div>All</div>
                            : filters[item.key].map(el => <div key={el}>
                              {el}
                            </div>)}
                        </div>
                    }
                    </div>
                  </div>
                ))} */}
              </div>}
            </div>}
            <div className={s.bottomSide}>
              <div className={classNames(s.filterRow, s.leftRow)}>
                <div>Events:</div>
                <div>
                  {events.length
                    ? events.map(event => (
                      <div key={event.id}>{`${event.name} - ${event.therapeuticArea} (${EVENT_TYPE_NAMES[event.eventType]})`}</div>
                    ))
                    : <div>No Events</div>}
                </div>
              </div>
            </div>
          </div>

        </Card>
        : <FilterCardLoader />
      }
    </div>
  );
};

export default FilterCard;
