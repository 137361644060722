export const EVENTS_SORT_OPTIONS = [
  { value: 'name', label: 'Name of Event' },
  { value: 'date', label: 'Date of Event' },
  { value: 'city', label: 'City' },
  { value: 'country', label: 'Country' },
  { value: 'region', label: 'Region' },
  { value: 'expectedParticipants', label: 'Number of Participants' },
];

export const EVENT_CONFIGS_KEYS = {
  AREA: 'Therapeutic Area',
  CITY: 'City',
  COUNTRY: 'Country',
  REGION: 'Region',
  LANGUAGE: 'Languages',
};
