import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../components/Heading/Heading';
import FilterCard from '../../../components/FilterCard/FilterCard';
import s from '../CaseEventAnalysis.module.scss';
import { getQuestionAnalysThunk } from '../../../redux/thunks/questionAnalysThunk';
import NoItem from '../../../components/NoItem/NoItem';
import QuestionContent from './components/QuestionContent/QuestionContent';
import { resetQuestionAnalys } from '../../../redux/slices/questionAnalysSilce';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';
import { EVENT_KEYS } from '../../../constants/names';
import { selectModeratorActiveEvent } from '../../../redux/slices/moderatorSlice';

const QuestionsAnswers = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);
  const { result, doctors } = useSelector(state => state.questionAnalys.analys);
  const isLoading = useSelector(state => state.questionAnalys.isLoading);
  const { eventsIds } = useSelector(state => state.filteredEvents.eventsData);
  const filterCases = useSelector(state => state.filteredEvents.filters[EVENT_KEYS.CASE_LIST]);
  const moderatorEvent = useSelector(selectModeratorActiveEvent);

  useEffect(() => {
    if (eventsIds.length || (moderatorEvent && isModerator)) {
      const data = {
        events: !isModerator ? eventsIds : [ moderatorEvent.id ],
      };
      if (!isModerator) {
        data.caseId = filterCases[0];
      }
      dispatch(getQuestionAnalysThunk(data));
    }
    return () => {
      dispatch(resetQuestionAnalys());
    };
  }, [ eventsIds, moderatorEvent ]);

  return (
    <div className={s.wrapper}>
      <Heading reset={resetQuestionAnalys} />

      {!isModerator && <FilterCard />}
      <div className={s.contentBoard}>
        {!isLoading && (result && doctors
          ? <QuestionContent />
          : <div className={s.noEvent}><NoItem analys /></div>)
        }
      </div>
    </div>
  );
};

export default QuestionsAnswers;
