import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import { setActiveAccessUser } from '../../../../redux/slices/accessSlice';
import { deleteAccessUserThunk, getAccessUsersThunk } from '../../../../redux/thunks/accessThunk';
import s from './AccessList.module.scss';

const AccessList = ({ isModerator }) => {
  const dispatch = useDispatch();
  const {
    isUsersLoading, users, activeUser,
  } = useSelector(state => state.access);

  useEffect(() => {
    dispatch(getAccessUsersThunk());
  }, []);

  const deleteModerator = (eventModeratorId) => {
    dispatch(deleteAccessUserThunk({ eventModeratorId }));
  };

  const onDeleteClick = (id) => {
    dispatch(setConfirm(() => deleteModerator(id)));
  };
  return (
    <div className={s.root}>
      <div className={s.header}>
        <div>Username</div>
        <div>Password</div>
      </div>
      <div className={s.list}>
        {users.map(moderator => (
          <div
            key={moderator.id}
            className={classNames(s.listItem, {
              [s.listItemActive]: moderator.id === activeUser?.id,
            })}>
            <div onClick={() => dispatch(setActiveAccessUser(moderator))} className={s.listItemInfo}>
              <div>{moderator.username}</div>
              <div>{moderator.password}</div>
            </div>
            <div className={s.recycleWrapper}>
              <div onClick={() => onDeleteClick(moderator.id)} className={s.recycleIcon} />
            </div>
          </div>
        ))}
        {!users.length && !isUsersLoading
        && <div className={s.noModerator}>Empty List</div>
        }
      </div>
    </div>
  );
};

export default AccessList;
