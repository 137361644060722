import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import { Button, Input } from '../../components/UI';
import RowLoader from '../../components/UI/Row/RowLoader';
import { resetSettings } from '../../redux/slices/settingsSlice';
import { getDictionariesThunk } from '../../redux/thunks/dictionarySettingsThunk';
import {
  getImagesThunk, getSettingsThunk, updateEventLinkThunk, updateTimerThunk,
  getEventLinkExpirationThunk, updateEventLinkExpirationThunk,
  getEventResetAnalysisThunk, updateEventResetAnalysisThunk,
} from '../../redux/thunks/settingsThunk';
import DictionariesModal from './components/DictionariesModal/DictionariesModal';
import ImagesModal from './components/ImagesModal/ImagesModal';
import s from './Settings.module.scss';

const Settings = () => {
  const dispatch = useDispatch();
  const sheetUrl = useSelector(state => state.userInfo.eventConfigs.sheet);
  const isConfigsLoading = useSelector(state => state.settings.isConfigsLoading);
  const inactiveTimer = useSelector(state => state.settings.interval);
  const eventLinkExpirationTime = useSelector(state => state.settings.eventLinkExpiration);
  const eventResetAnalysisTime = useSelector(state => state.settings.eventResetAnalysis);
  const isSettingsLoading = useSelector(state => state.settings.isSettingsLoading);
  const isTimerUpdateLoading = useSelector(state => state.settings.isTimerUpdateLoading);
  const isEventLinkExpirationLoading = useSelector(state => state.settings.isEventLinkExpirationLoading);
  const isEventLinkExpirationUpdateLoading = useSelector(state => state.settings.isEventLinkExpirationUpdateLoading);

  const isEventResetAnalysisLoading = useSelector(state => state.settings.isEventResetAnalysisLoading);
  const isEventResetAnalysisUpdateLoading = useSelector(state => state.settings.isEventResetAnalysisUpdateLoading);

  const [ isImageModalActive, setIsImageModalActive ] = useState(false);
  const [ isDictionaryModalActive, setIsDictionaryModalActive ] = useState(false);
  const [ fileUrl, setFileUrl ] = useState(sheetUrl || '');
  const [ timer, setTimer ] = useState(inactiveTimer || 0);
  const [ eventLinkExpirationTimer, setEventLinkExpirationTimer ] = useState(eventLinkExpirationTime || 0);
  const [ eventResetAnalysisTimer, setEventResetAnalysisTimer ] = useState(eventResetAnalysisTime || 0);

  useEffect(() => {
    dispatch(getSettingsThunk());
    dispatch(getEventLinkExpirationThunk());
    dispatch(getEventResetAnalysisThunk());
    dispatch(getImagesThunk());
    dispatch(getDictionariesThunk());
    return () => dispatch(resetSettings());
  }, []);

  useEffect(() => {
    setTimer(inactiveTimer);
  }, [ inactiveTimer ]);

  useEffect(() => {
    setEventLinkExpirationTimer(eventLinkExpirationTime);
  }, [ eventLinkExpirationTime ]);

  useEffect(() => {
    setEventResetAnalysisTimer(eventResetAnalysisTime);
  }, [ eventResetAnalysisTime ]);

  const onLinkClick = () => {
    dispatch(updateEventLinkThunk({ sheet: fileUrl }));
  };

  const onTimerHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if (num === '' || re.test(num)) {
      setTimer(num);
    }
  };

  const plusHandler = () => {
    setTimer((+timer || 0) + 1);
  };

  const minusHandler = () => {
    if (+timer && +timer - 1 >= 0) {
      setTimer(+timer - 1);
    }
  };

  const updateTimer = () => {
    dispatch(updateTimerThunk(timer));
  };

  const onEventExpirationTimerHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if (num === '' || re.test(num)) {
      setEventLinkExpirationTimer(num);
    }
  };

  const eventExpplusHandler = () => {
    setEventLinkExpirationTimer((+eventLinkExpirationTimer || 0) + 1);
  };

  const eventExpminusHandler = () => {
    if (+eventLinkExpirationTimer && +eventLinkExpirationTimer - 1 >= 0) {
      setEventLinkExpirationTimer(+eventLinkExpirationTimer - 1);
    }
  };

  const updateEventExpirationTimer = () => {
    dispatch(updateEventLinkExpirationThunk(eventLinkExpirationTimer));
  };

  const onEventResetAnalysisTimerHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if (num === '' || re.test(num)) {
      setEventResetAnalysisTimer(num);
    }
  };

  const eventResetPlusHandler = () => {
    setEventResetAnalysisTimer((+eventResetAnalysisTimer || 0) + 1);
  };

  const eventResetMinusHandler = () => {
    if (+eventResetAnalysisTimer && +eventResetAnalysisTimer - 1 >= 0) {
      setEventResetAnalysisTimer(+eventResetAnalysisTimer - 1);
    }
  };

  const updateEventResetAnalysisTimer = () => {
    dispatch(updateEventResetAnalysisThunk(eventResetAnalysisTimer));
  };

  return (
    <div>
      <Heading />
      <div className={s.settings}>
        <div className={s.settingItem}>
          <div className={s.settingLable}>Event Input File:</div>
          <div className={s.settingInput}>
            <Input value={fileUrl} onChange={e => setFileUrl(e.target.value)} />
          </div>
          <div>
            <Button
              loading={isConfigsLoading}
              onClick={onLinkClick}
              disabled={!fileUrl}
              className={s.linkButton}>
              {fileUrl && fileUrl === sheetUrl ? 'Update' : 'Link'}
            </Button>
          </div>
        </div>
        <div className={s.settingItem}>
          <div className={s.settingLable}>Inactive Timer <br /> (in minutes):</div>
          <div className={s.timerWrapper}>
            <div className={s.timerInput}>
              {isSettingsLoading
                ? <RowLoader />
                : <><div>
                  <Input
                    value={timer}
                    onChange={onTimerHandler}
                    type='tel' />
                </div>
                  <div className={s.plusMinus}>
                    <div onClick={plusHandler}>+</div>
                    <div onClick={minusHandler}>-</div>
                  </div>
                </>
              }

            </div>
            {/* <div className={s.infiniteCheckbox}><CheckboxPure /> Infinite</div> */}
            <div>
              <Button
                disabled={isSettingsLoading || +inactiveTimer === +timer}
                loading={isTimerUpdateLoading}
                onClick={updateTimer}
                className={s.imagesButton}>
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className={s.settingItem}>
          <div className={s.settingLable}>Event Link Expiration <br /> (in hours):</div>
          <div className={s.timerWrapper}>
            <div className={s.timerInput}>
              {isEventLinkExpirationLoading
                ? <RowLoader />
                : <><div>
                  <Input
                    value={eventLinkExpirationTimer}
                    onChange={onEventExpirationTimerHandler}
                    type='tel' />
                </div>
                  <div className={s.plusMinus}>
                    <div onClick={eventExpplusHandler}>+</div>
                    <div onClick={eventExpminusHandler}>-</div>
                  </div>
                </>
              }

            </div>
            {/* <div className={s.infiniteCheckbox}><CheckboxPure /> Infinite</div> */}
            <div>
              <Button
                disabled={isSettingsLoading || +eventLinkExpirationTime === +eventLinkExpirationTimer}
                loading={isEventLinkExpirationUpdateLoading}
                onClick={updateEventExpirationTimer}
                className={s.imagesButton}>
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className={s.settingItem}>
          <div className={s.settingLable}>Reset Analysis automatically before Events <br /> (in minutes):</div>
          <div className={s.timerWrapper}>
            <div className={s.timerInput}>
              {isEventResetAnalysisLoading
                ? <RowLoader />
                : <><div>
                  <Input
                    value={eventResetAnalysisTimer}
                    onChange={onEventResetAnalysisTimerHandler}
                    type='tel' />
                </div>
                  <div className={s.plusMinus}>
                    <div onClick={eventResetPlusHandler}>+</div>
                    <div onClick={eventResetMinusHandler}>-</div>
                  </div>
                </>
              }

            </div>
            {/* <div className={s.infiniteCheckbox}><CheckboxPure /> Infinite</div> */}
            <div>
              <Button
                disabled={isSettingsLoading || +eventResetAnalysisTime === +eventResetAnalysisTimer}
                loading={isEventResetAnalysisUpdateLoading}
                onClick={updateEventResetAnalysisTimer}
                className={s.imagesButton}>
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className={s.tabs}>
          <div>
            <Button
              onClick={() => setIsImageModalActive(true)}
              className={s.imagesButton}>Images</Button>
          </div>
          <div>
            <Button
              onClick={() => setIsDictionaryModalActive(true)}
              className={s.languagesButton}>UI Languages</Button>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isImageModalActive
      && <ImagesModal
        closeModal={() => setIsImageModalActive(false)} />}
      {isDictionaryModalActive
      && <DictionariesModal
        closeModal={() => setIsDictionaryModalActive(false)} />}
    </div>
  );
};

export default Settings;
