import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import NoItem from '../../components/NoItem/NoItem';
import SortButton from '../../components/SortButton/SortButton';
import { Select } from '../../components/UI';
import { EVENTS_SORT_OPTIONS } from '../../configs/events.configs';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { resetEvents } from '../../redux/slices/eventsSilce';
import { getEventsThunk } from '../../redux/thunks/eventsDataThunk';
import EventCardLoader from './components/EventItem/EventCardLoader';
import EventItem from './components/EventItem/EventItem';
import s from './Events.module.scss';

const PastEvents = () => {
  const dispatch = useDispatch();
  const { events, isLoading } = useSelector(state => state.eventsData);
  const [ activeEvents, setActiveEvents ] = useState([]);
  const [ isDesc, setIsDesc ] = useState(false);
  const [ sortValue, setSortValue ] = useState(null);

  const isSortDisabled = events.length < 2;

  const { lastTicketRef, scrollLoading, restartFetch } = useInfiniteScroll(
    page => dispatch(getEventsThunk({
      page, isDesc, isPast: true, sort: sortValue?.value,
    })),
    [ isDesc, sortValue ],
    () => dispatch(resetEvents()),
  );

  const onEventClick = (id) => {
    if (activeEvents.includes(id)) {
      return setActiveEvents(activeEvents.filter(item => item !== id));
    }
    return setActiveEvents(prev => [ ...prev, id ]);
  };

  return (
    <div className={s.futureRoot}>
      <Heading>
        <div className={s.headingActions}>
          <SortButton
            disabled={isSortDisabled}
            desc={isDesc}
            onClick={() => !isSortDisabled && setIsDesc(prev => !prev)} />
          <div className={s.headingItem}>
            <Select
              isClearable
              isDisabled={isSortDisabled}
              value={sortValue}
              onChange={value => setSortValue(value)}
              options={EVENTS_SORT_OPTIONS}
              placeholder='Sort By' />
          </div>
        </div>
      </Heading>
      <div className={s.cards}>
        {(isLoading && !events.length)
          ? Array(1).fill(null).map((_, idx) => (
            <EventCardLoader key={idx} action closable past />
          ))
          : events.map((event, i) => {
            if (!event) return null;
            return (
              <EventItem
                past
                key={event.id}
                restartFetch={restartFetch}
                lastTicketRef={i === events.length - 1 ? lastTicketRef : null}
                event={event}
                isActive={activeEvents.includes(event.id)}
                onEventClick={onEventClick} />
            );
          })
        }
        {!isLoading && !events.length && <NoItem title='Event' />}
        {scrollLoading && <EventCardLoader action closable past />}
      </div>
    </div>
  );
};

export default PastEvents;
