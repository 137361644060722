import React from 'react';
import AccessCreate from './components/AccessCreate/AccessCreate';
import AccessUpdate from './components/AccessUpdate/AccessUpdate';
import s from './AccessForms.module.scss';

const AccessForms = ({ isModerator }) => {
  const title = isModerator ? 'Event Moderator' : 'Event And Case Administrator';

  return (
    <div className={s.root}>
      <AccessCreate isModerator={isModerator} title={title} />
      <AccessUpdate isModerator={isModerator} title={title} />
    </div>
  );
};

export default AccessForms;
