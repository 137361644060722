import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { resetFilteredEvents } from '../../redux/slices/filteredEventsSlice';
import { resetUser, selectIsAuth } from '../../redux/slices/userInfoSlice';
import s from './Menu.module.scss';

const Menu = ({ validRoutes }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const [ activeMenuItems, setActiveMenuItems ] = useState([ 'Events', 'Patient Cases', 'Overall Analysis', 'Case/Event Analysis' ]);

  const onDropdownHandler = (name) => {
    if (activeMenuItems.includes(name)) {
      return setActiveMenuItems(activeMenuItems.filter(item => item !== name));
    }
    return setActiveMenuItems(prev => [ ...prev, name ]);
  };

  const signOut = () => {
    localStorage.removeItem('token');
    dispatch(resetUser());
    dispatch(resetFilteredEvents());
  };

  return (
    <aside className={s.menu}>
      <div className={s.menuNavbar}>
        {isAuth && validRoutes.map((route, i) => {
          if (!route.multi) {
            return (
              <div key={route.name} className={s.menuTitle}>
                <NavLink activeClassName={s.activeItem} to={route.path} className={s.menuItem}>
                  {route.name}
                </NavLink>
              </div>
            );
          }
          return (
            <div
              key={route.name}
              className={s.dropdown}>
              <div
                onClick={() => onDropdownHandler(route.name)}
                className={classNames(s.menuItem, s.dropdownTitle)}>
                <div >{route.name}</div>
                <div className={classNames(s.arrow,
                  { [s.arrowActive]: activeMenuItems.includes(route.name) })} />
              </div>
              {activeMenuItems.includes(route.name) && (
              <div className={s.multiItems}>
                {route.childs.map((child, idx) => (
                  <NavLink
                    key={child.name}
                    className={classNames(s.menuItem, s.dropdownMenuItem)}
                    activeClassName={s.activeItem}
                  // onClick={() => setActiveDropdown(null)}
                    to={route.path + child.path}>
                    {child.name}
                  </NavLink>
                ))}
              </div>
              )}
            </div>
          );
        })}
      </div>
      {isAuth && <div onClick={signOut} className={s.signOut}>
        <div>Sign out</div>
        <div className={s.signOutIcon} />
      </div>
      }
    </aside>
  );
};

export default Menu;
