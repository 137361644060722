import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { ALERT_TYPES, ORDER_TYPES } from '../../constants/names';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

const { DESC, ASC } = ORDER_TYPES;

// GET EVENTS
export const getEventsThunk = createAsyncThunk(
  'eventsData/getEvents',
  async ({
    isPast, isDesc, page = 1, pageCount = 20, sort, isApproved,
  }, { dispatch }) => {
    const past = isPast ? 1 : 0;
    const order = isDesc ? DESC : ASC;
    const approved = isApproved ? 1 : 0;
    let url = `/event/list?isPast=${past}&order=${order}&page=${page}&pageCount=${pageCount}&isApproved=${approved}`;
    if (sort) {
      url = `${url}&sort=${sort}`;
    }
    const response = await AXIOS.get(url).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getEventsThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getEventsThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.events = state.events.concat(payload);
};

const getEventsThunkRejected = (state, { error }) => {
  state.isLoading = false;
  state.error = getErrorMessage(error);
};

export const createModifyEventThunk = createAsyncThunk(
  'eventsData/createModifyEvent',
  async ({
    isModify, data, closeModal, restartFetch,
  }, { dispatch }) => {
    const response = await AXIOS.post(`event/${isModify ? 'update' : 'create'}`, data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal();
    return response.data;
  },
);

const createModifyEventThunkPending = (state) => {
  state.isCreateLoading = true;
};

const createModifyEventThunkFulfilled = (state) => {
  state.isCreateLoading = false;
};

const createModifyEventThunkRejected = (state) => {
  state.isCreateLoading = false;
};

// ARCHIVE EVENTS

export const archiveEventThunk = createAsyncThunk(
  'eventsData/archiveDeleteEvent',
  async ({
    id, closeModal, restartFetch,
  }, { dispatch }) => {
    const response = await AXIOS.post('/event/archive', { id }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal && closeModal();
    return response.data;
  },
);

// UNARCHIVE EVENTS
export const unArchiveEventThunk = createAsyncThunk(
  'eventsData/unArchiveEvent',
  async ({
    id, closeModal, restartFetch,
  }, { dispatch }) => {
    const response = await AXIOS.post('/event/unarchive', { id }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal && closeModal();
    return response.data;
  },
);

// DELETE EVENTS

export const deleteEventThunk = createAsyncThunk(
  'eventsData/archiveDeleteEvent',
  async ({
    id, closeModal, restartFetch,
  }, { dispatch }) => {
    const response = await AXIOS.delete(`/event/delete?id=${id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    closeModal && closeModal();
    return response.data;
  },
);

const deleteEventThunkPending = (state, { payload }) => {
  state.isDeleteLoading = true;
};

const deleteEventThunkFulfilled = (state, { payload }) => {
  state.isDeleteLoading = false;
};

const deleteEventThunkRejected = (state, { payload }) => {
  state.isDeleteLoading = false;
};

// APPROVE DRAFT EVENT

export const approveDraftEventThunk = createAsyncThunk(
  'eventsData/approveDraftEvent',
  async ({
    id, isApprove, restartFetch,
  }, { dispatch }) => {
    const status = isApprove ? 1 : 0;
    const response = await AXIOS.post('event/approve', { id, status }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    restartFetch();
    return response.data;
  },
);

// RESET EVENT

export const resetEventThunk = createAsyncThunk(
  'eventsData/reset',
  async (events, { dispatch }) => {
    const response = await AXIOS.post('/event/reset', { events: [ events.id ]}).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    await AXIOS.get(`/poll/restart/${events.uuid}/${events.cases[0].id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(setAlert({ message: 'Success', type: ALERT_TYPES.SUCCESS }));
    return response.data;
  },
);

export const eventsDataExtraReducers = (builder) => {
  builder
    .addCase(getEventsThunk.pending, getEventsThunkPending)
    .addCase(getEventsThunk.fulfilled, getEventsThunkFulfilled)
    .addCase(getEventsThunk.rejected, getEventsThunkRejected)
    .addCase(createModifyEventThunk.pending, createModifyEventThunkPending)
    .addCase(createModifyEventThunk.fulfilled, createModifyEventThunkFulfilled)
    .addCase(createModifyEventThunk.rejected, createModifyEventThunkRejected)
    .addCase(deleteEventThunk.pending, deleteEventThunkPending)
    .addCase(deleteEventThunk.fulfilled, deleteEventThunkFulfilled)
    .addCase(deleteEventThunk.rejected, deleteEventThunkRejected);
};
