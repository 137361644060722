import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import s from './Wrapper.module.scss';
import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import Routes from '../Routes/Routes';
import { getValidRoutes } from '../Routes/routes.configs';
import Alert from '../Alert/Alert';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FiltersModal from '../FiltersModal/FiltersModal';
import { selectIsModerator } from '../../redux/slices/userInfoSlice';

const Wrapper = () => {
  const role = useSelector(state => state.userInfo.role);
  const isModerator = useSelector(selectIsModerator);

  const validRoutes = useMemo(() => getValidRoutes(role), [ role ]);

  return (
    <div className={s.wrapperOuter} >
      <div className={s.wrapperHeader}>
        <Header />
      </div>
      <div className={s.wrapperInner} >
        <div className={s.wrapperMenu}>
          <Menu validRoutes={validRoutes} />
        </div>
        <div className={s.wrapper}>
          <Routes validRoutes={validRoutes} />
        </div>
      </div>
      <Alert />
      {/* modals */}
      <ConfirmModal />
      {!isModerator && <FiltersModal />}
    </div>
  );
};

export default Wrapper;
