import { Button } from '../../../../../components/UI';
import s from './CurrentQuestion.module.scss';

const CurrentQuestion = ({
  activeTabData, activeMenu, activeTabConfigs, setActiveMenu, allTabs,
}) => {
  const { answers, comments, question } = activeTabData;

  const toggleMenu = (isNext) => {
    setActiveMenu(prev => (isNext ? prev + 1 : prev - 1));
  };

  return (
    <div className={s.currentQuestion}>
      <div className={s.currentQuestionHeader}>
        <div>{question.name}</div>
        <div>{question.question}</div>
      </div>
      <div className={s.currentPage}>
        <activeTabConfigs.Component data={answers} comments={comments} />
      </div>
      <div className={s.actions}>
        <div>
          {!!activeMenu && <Button prev onClick={() => toggleMenu()}>Previous</Button>}
        </div>
        <div>
          {activeMenu < allTabs.length - 1
          && <Button next onClick={() => toggleMenu(true)}>Next</Button>
          }
        </div>
      </div>
    </div>
  );
};

export default CurrentQuestion;
