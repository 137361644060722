import { useState } from 'react';
import { useSelector } from 'react-redux';
import { QUESTION_PAGE_CONFIGS } from '../../../../../configs/question.configs';
import SideBar from '../../../components/SideBar/SideBar';
import CurrentQuestion from '../QuestionRightSide/CurrentQuestion';
import s from '../../../CaseEventAnalysis.module.scss';
import { QUESTION_KEYS } from '../../../../../constants/names';

const { CHAPTER_NAME, ICON } = QUESTION_KEYS;

const QuestionContent = () => {
  const { result } = useSelector(state => state.questionAnalys.analys);
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const [ activeMenu, setActiveMenu ] = useState(0);

  const allTabs = Object.values(result);
  const allTitles = Object.keys(result);

  const getQuestionType = item => QUESTION_PAGE_CONFIGS[item.question.type];

  const activeTabData = allTabs[activeMenu];
  const activeTabConfigs = getQuestionType(activeTabData);

  return (
    <>
      <SideBar open={isSidebarOpen}>
        {allTabs.map((item, idx) => (
          <SideBar.Item
            key={allTitles[idx]}
            toggleSidebar={() => setIsSidebarOpen(prev => !prev)}
            active={activeMenu === idx}
            onClick={() => setActiveMenu(idx)}
            open={isSidebarOpen}
            text={item.question[CHAPTER_NAME]}
            icon={item.question[ICON]} />
        ))}
      </SideBar>
      <div className={s.currentPage}>
        {activeTabConfigs && <CurrentQuestion
          allTabs={allTabs}
          activeTabData={activeTabData}
          activeMenu={activeMenu}
          activeTabConfigs={activeTabConfigs}
          setActiveMenu={setActiveMenu} />}
      </div>
    </>
  );
};

export default QuestionContent;
