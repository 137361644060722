import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import AccessForms from './components/AccessForms/AccessForms';
import s from './Access.module.scss';
import AccessEvents from './components/AccessEvents/AccessEvents';
import AccessList from './components/AccessList/AccessList';
import { getOnlyLocationsThunk } from '../../redux/thunks/accessThunk';
import { resetAccessSlice, selectIsAccessFilterOpen } from '../../redux/slices/accessSlice';
import AccessFilterModal from './components/AccessFilter/AccessFilterModal';

const Access = ({ isModerator }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectIsAccessFilterOpen);

  useEffect(() => {
    dispatch(getOnlyLocationsThunk());

    return () => dispatch(resetAccessSlice());
  }, []);
  return (
    <div className={s.root}>
      <Heading />
      <div className={s.content}>
        <AccessForms isModerator={isModerator} />
        <AccessList />
        <div className={s.line} />
        <AccessEvents />
      </div>
      {isModalOpen && <AccessFilterModal />}
    </div>
  );
};

export default Access;
