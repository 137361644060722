import { createSlice } from '@reduxjs/toolkit';
import { EVENT_TYPE_IDS } from '../../constants/ids';
import { accessExtraReducers } from '../thunks/accessThunk';

const initialState = {
  users: [],
  isUsersLoading: true,
  activeUser: null,
  activeEvents: [],
  filteredEvents: [],
  isActiveEventsLoading: false,
  filterOptions: {
    isOpen: false,
    isDateActive: false,
    startDate: new Date(),
    endDate: new Date(),
    eventTypes: Object.values(EVENT_TYPE_IDS),
    locations: [],
  },
};

export const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setActiveAccessUser: (state, { payload }) => { state.activeUser = payload; },
    toggleAccessFilter: (state, { payload }) => { state.filterOptions.isOpen = payload; },
    toggleAccessDateActivity: (state, { payload }) => {
      state.filterOptions.isDateActive = payload;
    },
    toggleAccessEventTypes: (state, { payload }) => {
      if (state.filterOptions.eventTypes.includes(payload)) {
        state.filterOptions.eventTypes = state.filterOptions.eventTypes.filter(item => item !== payload);
      } else {
        state.filterOptions.eventTypes.push(payload);
      }
    },
    accessDateChange: (state, { payload }) => {
      const { date, isStart } = payload;
      const currentKey = isStart ? 'startDate' : 'endDate';
      state.filterOptions[currentKey] = date;
    },
    toggleAccessRegion: (state, { payload }) => {
      const {
        unchecked, index, all, isAllChecked,
      } = payload;
      if (all) {
        state.filterOptions.locations.forEach((item) => {
          item.unchecked = isAllChecked;
        });
      } else {
        state.filterOptions.locations[index].unchecked = !unchecked;
      }
    },
    toggleAccessCountry: (state, { payload }) => {
      const {
        unchecked, index, regionIndex, all, isAllChecked,
      } = payload;
      if (all) {
        state.filterOptions.locations.forEach((region) => {
          region.country.forEach((country) => {
            country.unchecked = isAllChecked;
          });
        });
      } else {
        state.filterOptions.locations[regionIndex].country[index].unchecked = !unchecked;
      }
    },
    toggleAccessCity: (state, { payload }) => {
      const {
        unchecked, index, regionIndex, countryIndex, all, isAllChecked,
      } = payload;
      if (all) {
        state.filterOptions.locations.forEach((region) => {
          region.country.forEach((country) => {
            country.city.forEach((city) => {
              city.unchecked = isAllChecked;
            });
          });
        });
      } else {
        state.filterOptions.locations[regionIndex].country[countryIndex].city[index].unchecked = !unchecked;
      }
    },
    resetAccessSlice: () => initialState,
  },
  extraReducers: accessExtraReducers,
});

// ACTIONS
export const {
  setActiveAccessUser, resetAccessSlice, toggleAccessFilter, toggleAccessDateActivity, toggleAccessEventTypes,
  accessDateChange, toggleAccessRegion, toggleAccessCountry, toggleAccessCity,
} = accessSlice.actions;

// SELECTORS
export const selectAccessUsers = state => state.access.users;
export const selectAccessFilterOptions = state => state.access.filterOptions;
export const selectIsAccessFilterOpen = state => state.access.filterOptions.isOpen;

export default accessSlice.reducer;
