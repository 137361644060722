import classNames from 'classnames';
import dayjs from 'dayjs';
import { PATIENT_BASE_INFO_KEYS } from '../../../../configs/patients.configs';
import { EVENT_TYPE_NAMES } from '../../../../constants/names';
import s from './EventItem.module.scss';

const {
  GENDER, AGE, FIRST_NAME, LAST_NAME, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const EventItemContent = ({
  event, isActive, short, approved,
}) => {
  const draftCases = event.cases.filter(item => !item.approved).map(el => el.id);

  return (
    <div className={s.content}>
      <div>
        <span className={approved ? (s.approvedEvent) : (s.draftEvent)}>
          {`${event.therapeuticArea} (${EVENT_TYPE_NAMES[event.eventType]})`}</span>
        {` - ${event.name}`}
        {!!draftCases.length && approved && <span> - <span className={classNames(s.warning, s.bold)}>Patient Case In Draft</span></span>}
      </div>
      <div>
        {(!short || isActive) && <span className={approved ? (s.approvedEvent) : (s.draftEvent)}>
          {event.city && `${event.city}, `}{`${event.country} (${event.region})`}</span>} -&nbsp;
        <span className={approved ? (s.approvedEvent) : (s.draftEvent)}>
          {`${dayjs(event.startDate).format('MMM D, YYYY')}`}</span> -&nbsp;
        <span className={approved ? (s.approvedEvent) : (s.draftEvent)}> {`
          ${Number(event.startTime.split(':')[0]) > 12 ? Math.floor(Number(event.startTime.split(':')[0]) - 12)
          : Number(event.startTime.split(':')[0])}:${event.startTime.split(':')[1]}
          ${Number(event.startTime.split(':')[0]) > 11 ? 'pm' : 'am'}
          ${event.utcName}`}</span>
        {!!event.cases?.length && <span>- Patient Case:&nbsp;
            {event.cases.map((item, i) => {
              const { baseInfo } = item;
              return (
                <span key={i} className={approved ? (s.approvedEvent) : (s.draftEvent)}>
                  {`${baseInfo[GENDER]} ${baseInfo[FIRST_NAME]}${baseInfo[LAST_NAME]
                    ? ` ${baseInfo[LAST_NAME]}` : ''}, ${baseInfo[AGE]} - ${baseInfo[VISIT]} Visit`}
                  {(event.cases.length > 1 && event.cases.length - 1 !== i) ? ', ' : ''}
                </span>
              );
            })}
        </span>
         }
      </div>
      {isActive && <><div>
        {`${event.expectedParticipants} expected participants`}</div>
      </>}
    </div>
  );
};

export default EventItemContent;
