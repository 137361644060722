import classNames from 'classnames';
import { Radio } from '../../../UI';
import CheckboxPure from '../../../UI/Checkbox/CheckboxPure';
import RowLoader from '../../../UI/Row/RowLoader';
import s from './FilterItemList.module.scss';

const FilterItemList = ({
  title, children, isAllChecked, onSelectAll, radio, loading, length, disabled, cases,
}) => (
  <div className={s.listWrapper}>
    {(disabled || (length === 1 && !cases)) && <div className={s.disabled} />}
    <div className={s.listHeader}><div>{title}</div></div>
    <div className={s.listBody}>
      {loading && Array(3).fill(null).map((_, i) => (
        <div key={i} className={s.loaderContainer}>
          <div>
            <RowLoader
              filter
              key={i}
              color={i % 2 && 'var(--color-white2)'}
              height='2.5rem' />
          </div>
        </div>
      ))}
      {!loading && !radio && length > 1 && <FilterItemList.Item checked={isAllChecked} onClick={onSelectAll} value={'Select All'} />}
      {!loading && !length && <div className={s.noItem}>no item</div>}
      {!loading && children}
    </div>
  </div>
);

FilterItemList.Item = ({
  value, checked, radio, disabled, ...props
}) => (
  <div {...props} className={classNames(s.listItem, { [s.disabledItem]: disabled })}>
    <div>{value}</div>
    <div>
      {radio
        ? <Radio size='18px' active={checked} />
        : <CheckboxPure checked={checked} />}
    </div>
  </div>
);

export default FilterItemList;
