import { ALL_ROUTES } from '../components/Routes/routes.configs';
import { GENDER_TYPES } from '../constants/names';
import manImage from '../assets/images/man.svg';
import womanImage from '../assets/images/woman.svg';

export function getHeadingTitle(path) {
  let title = '';
  const mainRoute = ALL_ROUTES.find(route => path.includes(route.path));
  title += mainRoute?.name || '';
  if (mainRoute?.multi) {
    const childRoute = mainRoute.childs.find(item => path.includes(item.path));
    title += ` - ${childRoute.name}`;
  }
  if (path === '/events/approved' || path === '/events/draft') {
    title = `Future ${title}`;
  }
  return title;
}

export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export const imageSizeChecker = async (file, maxHeight, maxWidth) => new Promise((res, rej) => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        const { height, width } = image;
        if (height > maxHeight || width > maxWidth) {
          return res(false);
        }
        return res(true);
      };
    };
  } catch (error) {
    rej(error);
  }
});

export const getTop10Items = (items = []) => {
  const copyItems = [ ...items ];
  const sortedItems = copyItems.sort((a, b) => b.count - a.count).slice(0, 10);

  return sortedItems;
};

export const modifySelectOptions = (option) => {
  if (!option) return [];
  return option.map(item => ({ value: item, label: item }));
};

export function getPatientDefaultImage(gender = GENDER_TYPES.MR) {
  if (gender === GENDER_TYPES.MR) {
    return manImage;
  }
  return womanImage;
}
