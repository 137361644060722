import classNames from 'classnames';
import s from './Checkbox.module.scss';

const CheckboxPure = ({ checked, big, disabled }) => (
  <div className={classNames(s.dropdownRow__checkbox, { [s.bigCheckbox]: big, [s.disabled]: disabled })}>
    <div className={classNames(s.dropdownRow__noted, { [s.dropdownRow__noted_active]: checked })}>
      <svg className={s.dropdownRow__svg}>
        <use xlinkHref="#ok" />
      </svg>
    </div>
  </div>
);

export default CheckboxPure;
