import React from 'react';
import TimePicker from 'react-time-picker';
import s from './Timepicker.module.scss';

const Timepicker = props => (
  <div className={s.root}>
    <TimePicker onChange={props.onChange} value={props.value} clearIcon={null} />
  </div>
);

export default Timepicker;
