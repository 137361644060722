import classNames from 'classnames';
import s from '../PatientCasesModal.module.scss';

const PatientVideosSection = ({
  activeVideoFiles, filesHandler, onRecycleClick, setShowVideo, isView,
}) => (
  <div>
    <div className={classNames(s.section, s.lastSection)}>
      <div className={s.row}>
        <div>Video File(s):</div>
        <div>
          {activeVideoFiles?.length
            ? activeVideoFiles.map((item, i) => {
              const thumbnailUrl = (!item.new ? process.env.REACT_APP_IMAGE_URL : '') + item.thumbnail;
              const videoUrl = (!item.new ? process.env.REACT_APP_IMAGE_URL : '') + item.videoUrl;
              const mimeType = item.mimetype;
              return (
                <div key={i} className={s.patientRowWrapper}>
                  <div className={s.imageCard}>
                    <div className={s.imageCardName}>{item.name}</div>
                    <div className={s.imageCardImage}>
                      {item.thumbnail && <img src={thumbnailUrl} alt='' />}
                    </div>
                    <div
                      onClick={() => setShowVideo({ videoUrl, mimeType })}
                      className={s.imageCardView}>{item.new ? '' : 'View'}</div>
                  </div>
                  {/* <Row className={s.patientRow}> */}
                  {/* {item.name} */}
                  {/* </Row> */}
                  {!isView && <div
                    onClick={() => onRecycleClick(item.name)}
                    className={s.recycleIcon} />}
                </div>
              );
            })
            : <div>
              {!isView && <div className={s.dropzone}>
                <label className={classNames(s.chooseButton, s.addImageButton, s.grayButton)} htmlFor='chooseFiles'>
                  Add Video File(s) +
                </label>
                <input
                  multiple
                  onChange={filesHandler}
                  type='file'
                  id='chooseFiles'
                  className={s.fileInput} />
              </div>}
            </div>
            }

        </div>
      </div>
      {!!activeVideoFiles?.length && <div className={s.row}>
        <div />
        <div>
          {!isView && <div className={s.dropzone}>
            <label className={classNames(s.chooseButton, s.addImageButton, s.grayButton)} htmlFor='chooseFilesOuter'>
              Add Video File(s) +
            </label>
            <input
              multiple
              onChange={filesHandler}
              type='file'
              id='chooseFilesOuter'
              className={s.fileInput} />
          </div>}
        </div>
        </div>}
    </div>
  </div>
);

export default PatientVideosSection;
