import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import SortButton from '../../components/SortButton/SortButton';
import { Select } from '../../components/UI';
import EventItem from './components/EventItem/EventItem';
import s from './Events.module.scss';
import EventCardLoader from './components/EventItem/EventCardLoader';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { getEventsThunk } from '../../redux/thunks/eventsDataThunk';
import { EVENTS_SORT_OPTIONS } from '../../configs/events.configs';
import { resetEvents } from '../../redux/slices/eventsSilce';
import NoItem from '../../components/NoItem/NoItem';
import QRPopup from '../../components/QRPopup/QRPopup';

const ApprovedEvents = () => {
  const dispatch = useDispatch();
  const { events, isLoading } = useSelector(state => state.eventsData);
  const [ activeEvents, setActiveEvents ] = useState([]);
  const [ isDesc, setIsDesc ] = useState(false);
  const [ sortValue, setSortValue ] = useState(null);
  const [ QRData, setQRData ] = useState(null);

  const isSortDisabled = events.length < 2;

  const { lastTicketRef, scrollLoading, restartFetch } = useInfiniteScroll(
    page => dispatch(getEventsThunk({
      page, isDesc, sort: sortValue?.value, isApproved: true,
    })),
    [ isDesc, sortValue ],
    () => {
      setActiveEvents([]);
      dispatch(resetEvents());
    },
  );

  const onEventClick = (id) => {
    if (activeEvents.includes(id)) {
      return setActiveEvents(activeEvents.filter(item => item !== id));
    }
    return setActiveEvents(prev => [ ...prev, id ]);
  };

  return (
    <div className={s.futureRoot}>
      <Heading>
        <div className={s.headingActions}>
          <SortButton
            disabled={isSortDisabled}
            desc={isDesc}
            onClick={() => !isSortDisabled && setIsDesc(prev => !prev)} />
          <div className={s.headingItem}>
            <Select
              isClearable
              isDisabled={isSortDisabled}
              value={sortValue}
              onChange={value => setSortValue(value)}
              options={EVENTS_SORT_OPTIONS}
              placeholder='Sort By' />
          </div>
        </div>
      </Heading>
      <div className={s.cards}>
        {(isLoading && !events.length)
          ? Array(1).fill(null).map((_, i) => (
            <EventCardLoader key={i} action closable />
          ))
          : events.map((event, idx) => {
            if (!event) return null;
            return (
              <EventItem
                key={event.id}
                approved
                restartFetch={restartFetch}
                event={event}
                lastTicketRef={idx === events.length - 1 ? lastTicketRef : null}
                isActive={activeEvents.includes(event.id)}
                setQRData={setQRData}
                onEventClick={onEventClick} />);
          })
        }
        {(!isLoading && !events.length) && <NoItem title='Event' />}
        {scrollLoading && <EventCardLoader action closable />}
      </div>

      {/* modal */}
      {QRData && <QRPopup onClose={() => setQRData(null)} data={QRData} />}
    </div>
  );
};

export default ApprovedEvents;
