import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLES_IDS } from '../../constants/ids';
import { USER_ROLES_NAMES } from '../../constants/names';
import s from './Header.module.scss';

const Header = () => {
  const isAuth = useSelector(state => state.userInfo.isAuth);
  const role = useSelector(state => state.userInfo.role);

  return (
    <header className={s.header}>
      <div className={s.headerInfo}>
        <div className={s.headerLogo}>
          <div className={s.headerLogo__icon}>
            <svg className={s.headerLogo__svg}>
              <use xlinkHref='#medqp' />
            </svg>
          </div>
          <div className={s.headerLogo__text}>Event / Case Management Answer Analysis</div>
        </div>
      </div>
      <div className={s.headerInfo}>
        {isAuth && (
          <div className={s.headerPharmaceutical}>
            <div className={s.headerPharmaceutical__icon}>
              <svg className={s.headerPharmaceutical__svg}>
                <use xlinkHref='#pharmaceutical-logo' />
              </svg>
            </div>
            <div
              className={classNames(s.userRole, {
                [s.superUser]: role === USER_ROLES_IDS.SUPER_USER,
              })}>
              {USER_ROLES_NAMES[role]}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
