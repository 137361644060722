import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from '../../AccessForms.module.scss';
import { Button } from '../../../../../../components/UI';
import { updateAccessUserThunk } from '../../../../../../redux/thunks/accessThunk';

const initalState = {
  username: '',
  password: '',
};

const AccessUpdate = ({ title }) => {
  const dispatch = useDispatch();
  const { activeUser, isUpdateLoading } = useSelector(state => state.access);
  const [ localActiveModerator, setLocalActiveModerator ] = useState(initalState);

  useEffect(() => {
    if (activeUser) {
      setLocalActiveModerator(activeUser);
    }
  }, [ activeUser ]);

  const formHandler = (e) => {
    const { name, value } = e.target;
    setLocalActiveModerator(prev => ({
      ...prev, [name]: value,
    }));
  };

  const onUpdate = () => {
    dispatch(updateAccessUserThunk(localActiveModerator));
  };

  return (
    <div className={classNames(s.formItem, s.formItemUpdate)}>
      <div className={s.formItemTitle}>Edit Existing {title}</div>
      <div className={s.formItemMain}>
        <div>
          <div className={s.formInputLabel}>Username</div>
          <div className={s.formInputWrapper}>
            <input
              disabled={!activeUser}
              value={localActiveModerator.username}
              name='username'
              onChange={formHandler} />
          </div>
        </div>
        <div>
          <div className={s.formInputLabel}>Password</div>
          <div className={s.formInputWrapper}>
            <input
              disabled={!activeUser}
              value={localActiveModerator.password}
              name='password'
              onChange={formHandler} />
          </div>
        </div>
      </div>
      <div className={s.formActions}>
        <Button
          variant='light'
          onClick={() => setLocalActiveModerator(initalState)}
          disabled={!localActiveModerator.username && !localActiveModerator.password}
          className={s.clearButton}>Clear</Button>
        <Button
          loading={isUpdateLoading}
          onClick={onUpdate}
          disabled={!localActiveModerator.username || !localActiveModerator.password}
          className={s.addButton}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default AccessUpdate;
