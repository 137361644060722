import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { Button, Input } from '../../components/UI';
import { loginUserThunk } from '../../redux/thunks/userInfoThunk';
import s from './Login.module.scss';

const Login = () => {
  const dispatch = useDispatch();
  const { isAuthLoading } = useSelector(state => state.userInfo);
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ isPasswordVisible, setIsPasswordVisible ] = useState(false);

  const buttonDisabled = !username.trim() || !password.trim();

  const loginUser = useCallback(
    debounce(() => dispatch(loginUserThunk({ username, password })), 500),
    [ username, password ],
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    loginUser();
  };

  return (
    <div className={s.root}>
      <div className={s.formBoard}>
        <div>
          <svg className={s.logo}>
            <use xlinkHref="#pharmaceutical-logo" />
          </svg>
        </div>
        <form>
          <div>
            <div className={s.inputLable}>Username</div>
            <Input value={username} onChange={e => setUsername(e.target.value)} />
          </div>
          <div className={s.secondInput}>
            <div className={s.inputLable}>Password</div>
            <Input
              eye
              eyeActive={isPasswordVisible}
              onEyeClick={() => setIsPasswordVisible(prev => !prev)}
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)} />
          </div>
          <div className={s.buttonWrapper}>
            <Button
              loading={isAuthLoading}
              disabled={buttonDisabled}
              onClick={onSubmitHandler}>
              SIGN IN
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
