import classNames from 'classnames';
import s from './SortButton.module.scss';

const SortButton = ({ desc, disabled, ...props }) => (
  <div {...props} className={classNames(s.root, { [s.disabled]: disabled })}>
    <div className={classNames(s.icon, { [s.desc]: desc })} />
  </div>
);

export default SortButton;
