import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can from '../../components/Can/Can';
import Heading from '../../components/Heading/Heading';
import NoItem from '../../components/NoItem/NoItem';
import SortButton from '../../components/SortButton/SortButton';
import { Button, Select } from '../../components/UI';
import { PATIENTS_SORT_OPTIONS } from '../../configs/patients.configs';
import { USER_ROLES_IDS } from '../../constants/ids';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { resetPatients } from '../../redux/slices/patientsSilce';
import { getPatientsThunk } from '../../redux/thunks/patientsDataThunk';
import PatientCasesModal from './components/PatientCasesModal/PatientCasesModal';
import PatientItem from './components/PatientItem/PatientItem';
import PatientItemLoader from './components/PatientItem/PatientItemLoader';
import PhotoPopup from '../../components/PhotoPopup/PhotoPopup';
import VideoPopup from '../../components/VideoPopup/VideoPopup';
import s from './PatientCases.module.scss';
import AddTranslationModal from './components/AddTranslationModal/AddTranslationModal';

const { SUPER_USER } = USER_ROLES_IDS;

const Draft = () => {
  const dispatch = useDispatch();
  const { patients, isLoading } = useSelector(state => state.patientsData);
  const [ activePatients, setActivePatients ] = useState([]);
  const [ modalInfo, setModalInfo ] = useState(null);
  const [ addTranslationModal, setAddTranslationModal ] = useState(null);
  const [ isDesc, setIsDesc ] = useState(false);
  const [ sortValue, setSortValue ] = useState(null);
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);

  const isSortDisabled = patients.length < 2;

  const { lastTicketRef, scrollLoading, restartFetch } = useInfiniteScroll(
    page => dispatch(getPatientsThunk({
      page, isDesc, sort: sortValue?.value,
    })),
    [ isDesc, sortValue ],
    () => dispatch(resetPatients()),
  );

  useEffect(() => () => dispatch(resetPatients()), []);

  const onEventClick = (id) => {
    if (activePatients.includes(id)) {
      return setActivePatients(activePatients.filter(item => item !== id));
    }
    return setActivePatients(prev => [ ...prev, id ]);
  };

  return (
    <div className={s.casesRoot}>
      <Heading>
        <div className={s.headingActions}>
          <SortButton
            disabled={isSortDisabled}
            desc={isDesc}
            onClick={() => !isSortDisabled && setIsDesc(prev => !prev)} />
          <div className={s.headingItem}>
            <Select
              isDisabled={isSortDisabled}
              isClearable
              value={sortValue}
              onChange={value => setSortValue(value)}
              options={PATIENTS_SORT_OPTIONS}
              placeholder='Sort By' />
          </div>
          <Can roles={[ SUPER_USER ]}>
            <div className={s.headingItem}>
              <Button onClick={() => setModalInfo({ type: 'Add' })} className={s.addButton}>
                Add Patient Case +
              </Button>
            </div>
          </Can>
        </div>
      </Heading>
      <div className={s.cards}>
        {(isLoading && !patients.length)
          ? Array(1).fill(null).map((_, idx) => (
            <PatientItemLoader key={idx} draft />
          ))
          : patients.map((patient, i) => (
            <PatientItem
              isTranslations
              restartFetch={restartFetch}
              key={patient.id}
              lastTicketRef={i === patients.length - 1 ? lastTicketRef : null}
              draft
              patient={patient}
              setModalInfo={setModalInfo}
              isActive={activePatients.includes(patient.id)}
              openAddTranslation={() => setAddTranslationModal(patient)}
              onEventClick={onEventClick} />))
      }
        {(!isLoading && !patients.length) && <NoItem title='Patient' />}
        {scrollLoading && <PatientItemLoader draft />}
      </div>

      {/* modal */}
      {modalInfo && <PatientCasesModal
        type={modalInfo.type}
        patient={modalInfo.data}
        isView={modalInfo.isView}
        restartFetch={restartFetch}
        setShowPhoto={setShowPhoto}
        setShowVideo={setShowVideo}
        closeModal={() => setModalInfo(null)} />}
      {addTranslationModal && <AddTranslationModal
        patient={addTranslationModal}
        restartFetch={restartFetch}
        closeModal={() => setAddTranslationModal(null)} />}
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showVideo
        && <VideoPopup video={showVideo} onClose={() => setShowVideo(null)} />
      }
    </div>
  );
};

export default Draft;
