import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/Heading/Heading';
import NoItem from '../../components/NoItem/NoItem';
import SortButton from '../../components/SortButton/SortButton';
import { Select } from '../../components/UI';
import { PATIENTS_SORT_OPTIONS } from '../../configs/patients.configs';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { resetPatients } from '../../redux/slices/patientsSilce';
import { getPatientsThunk } from '../../redux/thunks/patientsDataThunk';
import PatientItem from './components/PatientItem/PatientItem';
import PatientItemLoader from './components/PatientItem/PatientItemLoader';
import s from './PatientCases.module.scss';

const Approved = () => {
  const dispatch = useDispatch();
  const { patients, isLoading } = useSelector(state => state.patientsData);
  const [ activePatients, setActivePatients ] = useState([]);
  const [ isDesc, setIsDesc ] = useState(false);
  const [ sortValue, setSortValue ] = useState(null);

  const isSortDisabled = patients.length < 2;

  const { lastTicketRef, scrollLoading, restartFetch } = useInfiniteScroll(
    page => dispatch(getPatientsThunk({
      page, isDesc, isApproved: true, sort: sortValue?.value,
    })),
    [ isDesc, sortValue ],
    () => dispatch(resetPatients()),
  );

  useEffect(() => () => dispatch(resetPatients()), []);

  const onEventClick = (id) => {
    if (activePatients.includes(id)) {
      return setActivePatients(activePatients.filter(item => item !== id));
    }
    return setActivePatients(prev => [ ...prev, id ]);
  };

  return (
    <div className={s.casesRoot}>
      <Heading>
        <div className={s.headingActions}>
          <SortButton
            disabled={isSortDisabled}
            desc={isDesc}
            onClick={() => !isSortDisabled && setIsDesc(prev => !prev)} />
          <div className={s.headingItem}>
            <Select
              isDisabled={isSortDisabled}
              isClearable
              value={sortValue}
              onChange={value => setSortValue(value)}
              options={PATIENTS_SORT_OPTIONS}
              placeholder='Sort By' />
          </div>
        </div>
      </Heading>
      <div className={s.cards}>
        {(isLoading && !patients.length)
          ? Array(1).fill(null).map((_, idx) => (
            <PatientItemLoader key={idx} />
          ))
          : patients.map((patient, i) => (
            <PatientItem
              key={patient.id}
              isTranslations
              restartFetch={restartFetch}
              lastTicketRef={i === patients.length - 1 ? lastTicketRef : null}
              patient={patient}
              isActive={activePatients.includes(patient.id)}
              onEventClick={onEventClick} />))
       }
        {(!isLoading && !patients.length) && <NoItem title='Patient' />}
        {scrollLoading && <PatientItemLoader />}
      </div>
    </div>
  );
};

export default Approved;
