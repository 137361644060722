import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoItem from '../../../../components/NoItem/NoItem';
import { Button } from '../../../../components/UI';
import { toggleAccessFilter } from '../../../../redux/slices/accessSlice';
import { getAccessEventsThunk, selectAccessUserEventsThunk } from '../../../../redux/thunks/accessThunk';
import EventCardLoader from '../../../Events/components/EventItem/EventCardLoader';
import EventItem from '../../../Events/components/EventItem/EventItem';
import s from './AccessEvents.module.scss';

const AccessEvents = () => {
  const dispatch = useDispatch();
  const {
    activeUser, activeEvents, isActiveEventsLoading,
    filteredEvents, isFilteredEventsLoading, isSaveEventsLoading,
  } = useSelector(state => state.access);

  const [ openEvents, setOpenEvents ] = useState([]);
  const [ checkedActiveEvents, setCheckedActiveEvents ] = useState([]);
  const [ checkedFilteredvents, setCheckedFilteredEvents ] = useState([]);

  const nonActiveEvents = useMemo(() => {
    const activeEventsIds = activeEvents.map(el => el.id);
    return filteredEvents.filter(item => !activeEventsIds.includes(item.id));
  }, [ filteredEvents, activeEvents ]);

  useEffect(() => {
    if (activeUser) {
      dispatch(getAccessEventsThunk({ eventModeratorId: activeUser.id }));
    }
  }, [ activeUser ]);

  useEffect(() => {
    if (activeEvents) {
      const newActiveEventsIds = activeEvents.map(item => item.id);
      setCheckedActiveEvents(newActiveEventsIds);
      setCheckedFilteredEvents(prev => prev.filter(item => !newActiveEventsIds.includes(item)));
    }
  }, [ activeEvents ]);

  const onOpenClick = (id) => {
    if (openEvents.includes(id)) {
      return setOpenEvents(activeEvents.filter(item => item !== id));
    }
    return setOpenEvents(prev => [ ...prev, id ]);
  };

  const onSave = () => {
    dispatch(selectAccessUserEventsThunk({
      eventModeratorId: activeUser.id,
      events: [ ...checkedActiveEvents, ...checkedFilteredvents ],
    }));
  };

  const onEventCheck = (id, isActive) => {
    const currentAction = isActive ? setCheckedActiveEvents : setCheckedFilteredEvents;
    currentAction(prev => (prev.includes(id)
      ? prev.filter(el => el !== id)
      : [ ...prev, id ]));
  };

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div>Events</div>
        <div className={s.filterIcon} onClick={() => dispatch(toggleAccessFilter(true))} />
      </div>
      <div className={s.list}>
        <div className={s.listTitle}>Active Events</div>
        {isActiveEventsLoading
          ? <div className={s.listItem}>
            <EventCardLoader closable />
          </div>
          : activeEvents.map(event => (
            <div key={event.id} className={s.listItem}>
              <EventItem
                event={event}
                onEventClick={onOpenClick}
                checked={checkedActiveEvents.includes(event.id)}
                check
                onCheck={() => onEventCheck(event.id, true)}
                size='s'
                isActive={openEvents.includes(event.id)}
                select />
            </div>
          ))}
        {!isActiveEventsLoading && !activeEvents.length && <div className={s.listItem}>
          <NoItem>
            Empty Active Events
          </NoItem>
        </div>}
        <div className={s.listTitle}>Filtered Events</div>
        {isFilteredEventsLoading
          ? <div className={s.listItem}>
            <EventCardLoader closable />
          </div>
          : nonActiveEvents.map(event => (
            <div key={event.id} className={s.listItem}>
              <EventItem
                event={event}
                onEventClick={onOpenClick}
                check
                checked={checkedFilteredvents.includes(event.id)}
                onCheck={() => onEventCheck(event.id)}
                size='s'
                isActive={openEvents.includes(event.id)}
                select />
            </div>
          ))}
        {!isFilteredEventsLoading && !nonActiveEvents.length && <div className={s.listItem}>
          <NoItem>
            Empty Filtered Events
          </NoItem>
        </div>}
      </div>
      <div className={s.actions}>
        <Button
          disabled={(!checkedFilteredvents.length && (!activeEvents.length || checkedActiveEvents.length === activeEvents.length))}
          loading={isSaveEventsLoading}
          onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AccessEvents;
