import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';
import { updateConfigs } from '../slices/userInfoSlice';

// GET SETTINGS
export const getSettingsThunk = createAsyncThunk(
  'settings/get',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/settings/getSettings').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getSettingsThunkPending = (state) => {
  state.isSettingsLoading = true;
};

const getSettingsThunkFulfilled = (state, { payload }) => ({
  ...state,
  ...payload,
  isSettingsLoading: false,
});

const getSettingsThunkRejected = (state) => {
  state.isSettingsLoading = false;
};

// UPDATE INACTIVE TIMER
export const updateTimerThunk = createAsyncThunk(
  'settings/updateTimer',
  async (interval, { dispatch }) => {
    const response = await AXIOS.post('/settings/updateInterval', { interval }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getSettingsThunk());
    return response.data;
  },
);

const updateTimerThunkPending = (state) => {
  state.isTimerUpdateLoading = true;
};

const updateTimerThunkFulfilled = (state, { payload }) => {
  state.isTimerUpdateLoading = false;
};

const updateTimerThunkRejected = (state) => {
  state.isTimerUpdateLoading = false;
};

// EventLinkExpiration Thunk
export const getEventLinkExpirationThunk = createAsyncThunk(
  'settings/getEventLinkExpirationThunk',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/settings/getEventLinkExpiration').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getEventLinkExpirationThunkPending = (state) => {
  state.isEventLinkExpirationLoading = true;
};

const getEventLinkExpirationThunkFulfilled = (state, { payload }) => ({
  ...state,
  ...payload,
  isEventLinkExpirationLoading: false,
});

const getEventLinkExpirationThunkRejected = (state) => {
  state.isEventLinkExpirationLoading = false;
};

export const updateEventLinkExpirationThunk = createAsyncThunk(
  'settings/updateEventLinkExpiration',
  async (eventLinkExpiration, { dispatch }) => {
    const response = await AXIOS.post('/settings/updateEventLinkExpiration', { eventLinkExpiration }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getEventLinkExpirationThunk());
    return response.data;
  },
);

const updateEventLinkExpirationThunkPending = (state) => {
  state.isEventLinkExpirationUpdateLoading = true;
};

const updateEventLinkExpirationThunkFulfilled = (state, { payload }) => {
  state.isEventLinkExpirationUpdateLoading = false;
};

const updateEventLinkExpirationThunkRejected = (state) => {
  state.isEventLinkExpirationUpdateLoading = false;
};

// Event Reset Analysis Thunk
export const getEventResetAnalysisThunk = createAsyncThunk(
  'settings/getEventResetAnalysisThunk',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/settings/getEventResetAnalysis').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getEventResetAnalysisThunkPending = (state) => {
  state.isEventResetAnalysisLoading = true;
};

const getEventResetAnalysisThunkFulfilled = (state, { payload }) => ({
  ...state,
  ...payload,
  isEventResetAnalysisLoading: false,
});

const getEventResetAnalysisThunkRejected = (state) => {
  state.isEventResetAnalysisLoading = false;
};

export const updateEventResetAnalysisThunk = createAsyncThunk(
  'settings/updateEventResetAnalysis',
  async (eventResetAnalysis, { dispatch }) => {
    const response = await AXIOS.post('/settings/updateEventResetAnalysis', { eventResetAnalysis }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getEventResetAnalysisThunk());
    return response.data;
  },
);

const updateEventResetAnalysisThunkPending = (state) => {
  state.isEventResetAnalysisUpdateLoading = true;
};

const updateEventResetAnalysisThunkFulfilled = (state, { payload }) => {
  state.isEventResetAnalysisUpdateLoading = false;
};

const updateEventResetAnalysisThunkRejected = (state) => {
  state.isEventResetAnalysisUpdateLoading = false;
};

// GET IMAGES
export const getImagesThunk = createAsyncThunk(
  'settings/getImages',
  async (_, { dispatch }) => {
    const response = await AXIOS.get('/image/list').catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getImagesThunkPending = (state) => {
  state.isImagesLoading = true;
};

const getImagesThunkFulfilled = (state, { payload }) => {
  state.isImagesLoading = false;
  state.images = payload;
};

const getImagesThunkRejected = (state) => {
  state.isImagesLoading = false;
};

// ADD IMAGE

export const addImageThunk = createAsyncThunk(
  'settings/addImage',
  async ({ type, image }, { dispatch }) => {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('image', image);
    const response = await AXIOS.post('/image/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getImagesThunk());
    return response.data;
  },
);

export const deleteImageThunk = createAsyncThunk(
  'settings/deleteImage',
  async ({ id }, { dispatch }) => {
    const response = await AXIOS.delete(`/image/delete?id=${id}`).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(getImagesThunk());
    return response.data;
  },
);

// GET EVENT CONFIGS
export const updateEventLinkThunk = createAsyncThunk(
  'settings/setEventLink',
  async (data, { dispatch }) => {
    const response = await AXIOS.post('/event/configs', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    dispatch(updateConfigs(response.data));
    dispatch(setAlert({ message: 'SUCCESS', type: 'success' }));
    return response.data;
  },
);

const updateEventLinkThunkPending = (state) => {
  state.isConfigsLoading = true;
};

const updateEventLinkThunkFuldilled = (state, { payload }) => {
  state.isConfigsLoading = false;
};

const updateEventLinkThunkRejected = (state) => {
  state.isConfigsLoading = false;
};

export const settingsExtraReducers = (builder) => {
  builder
    .addCase(getSettingsThunk.pending, getSettingsThunkPending)
    .addCase(getSettingsThunk.fulfilled, getSettingsThunkFulfilled)
    .addCase(getSettingsThunk.rejected, getSettingsThunkRejected)
    .addCase(updateTimerThunk.pending, updateTimerThunkPending)
    .addCase(updateTimerThunk.fulfilled, updateTimerThunkFulfilled)
    .addCase(updateTimerThunk.rejected, updateTimerThunkRejected)
    .addCase(getEventLinkExpirationThunk.pending, getEventLinkExpirationThunkPending)
    .addCase(getEventLinkExpirationThunk.fulfilled, getEventLinkExpirationThunkFulfilled)
    .addCase(getEventLinkExpirationThunk.rejected, getEventLinkExpirationThunkRejected)
    .addCase(updateEventLinkExpirationThunk.pending, updateEventLinkExpirationThunkPending)
    .addCase(updateEventLinkExpirationThunk.fulfilled, updateEventLinkExpirationThunkFulfilled)
    .addCase(updateEventLinkExpirationThunk.rejected, updateEventLinkExpirationThunkRejected)
    .addCase(getEventResetAnalysisThunk.pending, getEventResetAnalysisThunkPending)
    .addCase(getEventResetAnalysisThunk.fulfilled, getEventResetAnalysisThunkFulfilled)
    .addCase(getEventResetAnalysisThunk.rejected, getEventResetAnalysisThunkRejected)
    .addCase(updateEventResetAnalysisThunk.pending, updateEventResetAnalysisThunkPending)
    .addCase(updateEventResetAnalysisThunk.fulfilled, updateEventResetAnalysisThunkFulfilled)
    .addCase(updateEventResetAnalysisThunk.rejected, updateEventResetAnalysisThunkRejected)
    .addCase(getImagesThunk.pending, getImagesThunkPending)
    .addCase(getImagesThunk.fulfilled, getImagesThunkFulfilled)
    .addCase(getImagesThunk.rejected, getImagesThunkRejected)
    .addCase(updateEventLinkThunk.pending, updateEventLinkThunkPending)
    .addCase(updateEventLinkThunk.fulfilled, updateEventLinkThunkFuldilled)
    .addCase(updateEventLinkThunk.rejected, updateEventLinkThunkRejected);
};
