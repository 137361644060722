import { useSelector } from 'react-redux';
import Heading from '../../../components/Heading/Heading';
import FilterCard from '../../../components/FilterCard/FilterCard';
import PastAnalysisList from './components/PastAnalysisList/PastAnalysisList';
import s from './PastEventAnalysis.module.scss';
import NoItem from '../../../components/NoItem/NoItem';

const PastEventAnalysis = () => {
  const events = useSelector(state => state.filteredEvents.eventsData.events);

  return (
    <div className={s.pastAnalysis}>
      <Heading />
      <FilterCard />
      {
        events.length
          ? <PastAnalysisList />
          : <div className={s.noEvent}><NoItem analys /></div>
      }
    </div>
  );
};

export default PastEventAnalysis;
