import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import classNames from 'classnames';
import { Card, Radio } from '../../../../components/UI';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import {
  approveDraftEventThunk, archiveEventThunk, deleteEventThunk, resetEventThunk,
  unArchiveEventThunk,
} from '../../../../redux/thunks/eventsDataThunk';
import EventItemContent from './EventItemContent';
import s from './EventItem.module.scss';
import { ALERT_TYPES } from '../../../../constants/names';
import { setAlert } from '../../../../redux/slices/alertSlice';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import Can from '../../../../components/Can/Can';
import { USER_ROLES_IDS } from '../../../../constants/ids';
import { selectRole } from '../../../../redux/slices/userInfoSlice';
import { getFilteredEventsThunk } from '../../../../redux/thunks/filteredEventsThunk';
import { getModeratorEventsThunk } from '../../../../redux/thunks/moderatorThunk';

const { SUPER_USER } = USER_ROLES_IDS;

const EventItem = ({
  event, onEventClick = () => {}, isActive, setModalInfo, past, draft, approved, select,
  lastTicketRef, restartFetch, checked, onCheckClick, check, closable = true, size = 'm',
  short, radio, setQRData, onCheck, moderator,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const eventsIds = useSelector(state => state.filteredEvents.eventsData.eventsIds);
  const [ isDotsDropdownActive, setIsDotsDropdownActive ] = useState(false);
  const [ isShareDropdownActive, setIsShareDropdownActive ] = useState(false);
  const [ isShareDropdown2Active, setIsShareDropdown2Active ] = useState(false);

  const isView = role !== SUPER_USER;

  const archiveEvent = (isDelete) => {
    const action = isDelete ? deleteEventThunk : archiveEventThunk;
    dispatch(action({ id: event.id, isDelete, restartFetch }));
  };

  const onArchiveDeleteClick = (isDelete) => {
    dispatch(setConfirm(() => archiveEvent(isDelete)));
  };

  const approveDraftEvent = (isApprove) => {
    dispatch(approveDraftEventThunk({ id: event.id, isApprove, restartFetch }));
  };

  const onApproveDraftClick = (isApprove) => {
    dispatch(setConfirm(() => approveDraftEvent(isApprove)));
  };

  const onUrlCopyClick = () => {
    const url = `${process.env.REACT_APP_FRONT_URL}`;
    navigator.clipboard.writeText(url + event.uuid);
    dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
    setIsShareDropdownActive(false);
  };

  const onUrlCopy2Click = () => {
    const url = `${process.env.REACT_APP_DI_URL}`;
    navigator.clipboard.writeText(url + event.uuid);
    dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
    setIsShareDropdown2Active(false);
  };

  const onDotsClick = () => {
    setIsDotsDropdownActive(true);
  };

  const onEditClick = () => {
    setModalInfo({ type: 'Modify', data: event, isView });
  };

  const onDuplicateClick = () => {
    setModalInfo({ type: 'Add', data: event });
    setIsDotsDropdownActive(false);
  };

  const deleteEvent = () => {
    dispatch(deleteEventThunk({
      id: event.id, closeModal: setModalInfo(null), restartFetch,
    }));
  };

  const onDeleteClick = () => {
    dispatch(setConfirm(deleteEvent));
    setIsDotsDropdownActive(false);
  };

  const onResetClick = () => {
    if (!event.count) return dispatch(setAlert({ message: 'No details to reset!', type: ALERT_TYPES.WARNING }));
    dispatch(setConfirm(() => {
      dispatch(resetEventThunk(event)).unwrap().then(() => {
        moderator ? dispatch(getModeratorEventsThunk()) : restartFetch();
        if (eventsIds.length) {
          dispatch(getFilteredEventsThunk());
        }
      });
    }));
    setIsDotsDropdownActive(false);
  };

  const onUnArchiveClick = (isUnarchive) => {
    dispatch(unArchiveEventThunk({ id: event.id, isUnarchive, restartFetch }));
  };

  const setQR = () => {
    setQRData({ event, eventURL: process.env.REACT_APP_FRONT_URL + event.uuid });
    setIsShareDropdownActive(false);
  };

  const setQRParticipants = () => {
    setQRData({ event, eventURL: process.env.REACT_APP_DI_URL + event.uuid });
    setIsShareDropdown2Active(false);
  };

  return (
    <div ref={lastTicketRef} className={s.cardItem}>
      <Card
        closable={closable}
        active={isActive}
        checked={checked}
        check={check}
        onCheck={onCheck}
        onClick={() => onEventClick(event.id)}
        size={size}
        actions={!select && <div className={s.actions}>
          {!check && !radio && past && !isView && (
            <div className={s.actions}>
              <div
                onClick={() => onUnArchiveClick(true)}
                className={s.actionItem}>Unarchive</div>
              <div
                onClick={() => onArchiveDeleteClick(true)}
                className={classNames(s.actionItem, s.warning)}>delete</div>
            </div>
          )
          }
          {!check && !radio && draft && <>
            <div onClick={onEditClick} className={s.actionItem}>{isView ? 'View' : 'Edit'}</div>
            <Can roles={[ SUPER_USER ]}>
              <div onClick={() => onApproveDraftClick(true)} className={s.actionItem}>Approve</div>
            </Can>
            <div onClick={onResetClick} className={s.actionItem}>Reset Analysis</div>
            <Can roles={[ SUPER_USER ]}>
              <div onClick={() => onArchiveDeleteClick()} className={s.actionItem}>Archive</div>
            </Can>
            <Can roles={[ SUPER_USER ]}>
              <div className={s.dotsWrapper}>
                <div className={s.dots} onClick={onDotsClick} />
                <Dropdown
                  toggle={setIsDotsDropdownActive}
                  active={isDotsDropdownActive}
                  onlyModal>
                  <Dropdown.Modal className={s.dotsDropdown}>
                    <div className={s.dotsDropdownList}>
                      <div
                        onClick={onDuplicateClick}
                        className={s.dotsDropdownItem}>
                        Duplicate
                      </div>
                      <div
                        onClick={onDeleteClick}
                        className={s.dotsDropdownItem}>
                        Delete
                      </div>
                    </div>
                  </Dropdown.Modal>
                </Dropdown>
              </div>
            </Can>
            </>}
            {!check && !radio && approved && <>
              <div className={s.dotsWrapper} >
                <div
                  onClick={() => setIsShareDropdownActive(true)}
                  className={s.actionItem}>Link for Moderator</div>
                <Dropdown
                  toggle={setIsShareDropdownActive}
                  active={isShareDropdownActive}
                  onlyModal>
                  <Dropdown.Modal className={s.shareDropdown}>
                    <div className={s.dotsDropdownList}>
                      <div
                        onClick={onUrlCopyClick}
                        className={s.dotsDropdownItem}>
                        A URL link
                      </div>
                      <div
                        onClick={setQR}
                        className={s.dotsDropdownItem}>
                        A QR code
                      </div>
                    </div>
                  </Dropdown.Modal>
                </Dropdown>
              </div>
              <div className={s.dotsWrapper} >
                <div
                  onClick={() => setIsShareDropdown2Active(true)}
                  className={s.actionItem}>Link for Participants</div>
                <Dropdown
                  toggle={setIsShareDropdown2Active}
                  active={isShareDropdown2Active}
                  onlyModal>
                  <Dropdown.Modal className={s.shareDropdown}>
                    <div className={s.dotsDropdownList}>
                      <div
                        onClick={onUrlCopy2Click}
                        className={s.dotsDropdownItem}>
                        A URL link
                      </div>
                      <div
                        onClick={setQRParticipants}
                        className={s.dotsDropdownItem}>
                        A QR code
                      </div>
                    </div>
                  </Dropdown.Modal>
                </Dropdown>
              </div>
              <Can roles={[ SUPER_USER ]}>
                <div
                  onClick={() => onApproveDraftClick()}
                  className={s.actionItem}>Send to Draft</div>
              </Can>
              <Can roles={[ SUPER_USER ]}>
                <div onClick={() => onArchiveDeleteClick()} className={s.actionItem}>Archive</div>
              </Can>
              <div onClick={onResetClick} className={s.actionItem}>Reset Analysis</div>
            </>}
            {moderator && (
              <>
                <div className={s.dotsWrapper} >
                  <div
                    onClick={() => setIsShareDropdownActive(true)}
                    className={s.actionItem}>Share</div>
                  <Dropdown
                    toggle={setIsShareDropdownActive}
                    active={isShareDropdownActive}
                    onlyModal>
                    <Dropdown.Modal className={s.shareDropdown}>
                      <div className={s.dotsDropdownList}>
                        <div
                          onClick={onUrlCopyClick}
                          className={s.dotsDropdownItem}>
                          A URL link
                        </div>
                        <div
                          onClick={setQR}
                          className={s.dotsDropdownItem}>
                          A QR code
                        </div>
                      </div>
                    </Dropdown.Modal>
                  </Dropdown>
                </div>
                <div onClick={onResetClick} className={s.actionItem}>Reset Analysis</div>
              </>
            )
            }
            {/* {check && <div className={s.checkbox}>
              <Checkbox checked={checked} onChange={onCheckClick} />
              </div>} */}
          {radio && <div className={s.checkbox}>
            <Radio size='26px' active={checked} onClick={onCheckClick} />
          </div>}
        </div>
        }>
        <EventItemContent short={short} event={event} isActive={isActive} approved={approved} />
      </Card>
    </div>
  );
};

export default EventItem;
