import { TEMPLATE_TYPES } from '../constants/names';
import Question1 from '../pages/CaseEventAnalysis/QuestionsAnswers/components/Question1/Question1';
import Question2 from '../pages/CaseEventAnalysis/QuestionsAnswers/components/Question2/Question2';

const { MULTIPE_CHOICE, RANKING } = TEMPLATE_TYPES;

export const QUESTION_PAGE_CONFIGS = {
  [MULTIPE_CHOICE]: {
    Component: Question1,
  },
  [RANKING]: {
    Component: Question2,
  },
};
